.header-section {
  min-height: 100vh;
  background-image: url("../images/background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%; }
  .header-section .flags-container {
    position: absolute;
    top: 0;
    right: 0;
    margin: 12px; }
    .header-section .flags-container .flag {
      margin: 4px;
      height: 32px;
      width: 32px; }
  .header-section .gradient-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.4);
    min-height: calc(100vh - 64px);
    padding: 32px;
    width: calc(100% - 64px); }
    .header-section .gradient-container .logo {
      margin-top: 48px;
      height: 250px;
      width: 250px;
      opacity: 0; }
    .header-section .gradient-container .arrow-down-button {
      margin-top: 48px;
      height: 64px;
      background: transparent;
      border: none;
      outline: none;
      opacity: 0;
      cursor: pointer; }
      .header-section .gradient-container .arrow-down-button .arrow-down {
        height: 64px; }
    .header-section .gradient-container .logo.opacity-transition-750ms, .header-section .gradient-container .arrow-down-button.opacity-transition-750ms {
      opacity: 1;
      transition: 0.75s opacity ease; }

@media only screen and (min-width: 768px) {
  .header-section .gradient-container .logo {
    margin-top: 72px;
    height: 350px;
    width: 350px; }
  .header-section .gradient-container .arrow-down-button {
    margin-top: 72px; } }

@media only screen and (min-width: 1024px) {
  .header-section .gradient-container .logo {
    height: 400px;
    width: 400px; } }

.team-section .team-title {
  font-size: 56px;
  text-align: center; }

.team-section .members-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }
  .team-section .members-container .team-member {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 32px;
    width: 256px; }
    .team-section .members-container .team-member .member-profile {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      margin-bottom: 16px; }
    .team-section .members-container .team-member .member-name {
      margin: 0;
      font-size: 28px; }
    .team-section .members-container .team-member .member-position {
      margin: 8px 0 8px 0;
      font-size: 24px; }
    .team-section .members-container .team-member .member-email {
      color: black;
      font-size: 18px; }

@media only screen and (min-width: 1024px) {
  .team-section .members-container .team-member {
    width: 230px; } }

.sponsors-section {
  text-align: center; }
  .sponsors-section .sponsors-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center; }
    .sponsors-section .sponsors-container .sponsor {
      cursor: pointer;
      margin: 16px; }
      .sponsors-section .sponsors-container .sponsor.spse, .sponsors-section .sponsors-container .sponsor.spse .logo {
        height: 120px; }
      .sponsors-section .sponsors-container .sponsor.prusa-research, .sponsors-section .sponsors-container .sponsor.prusa-research .logo {
        height: 120px; }
      .sponsors-section .sponsors-container .sponsor.arduino-shop, .sponsors-section .sponsors-container .sponsor.arduino-shop .logo {
        height: 72px; }
      .sponsors-section .sponsors-container .sponsor.svitilny, .sponsors-section .sponsors-container .sponsor.svitilny .logo {
        height: 72px; }
      .sponsors-section .sponsors-container .sponsor.radio-shop, .sponsors-section .sponsors-container .sponsor.radio-shop .logo {
        height: 48px; }
      .sponsors-section .sponsors-container .sponsor.gradient, .sponsors-section .sponsors-container .sponsor.gradient .logo {
        height: 110px; }
      .sponsors-section .sponsors-container .sponsor.p-one-team, .sponsors-section .sponsors-container .sponsor.p-one-team .logo {
        height: 110px; }
      .sponsors-section .sponsors-container .sponsor.trivox, .sponsors-section .sponsors-container .sponsor.trivox .logo {
        height: 36px; }
      .sponsors-section .sponsors-container .sponsor.mff, .sponsors-section .sponsors-container .sponsor.mff .logo {
        height: 80px; }
      .sponsors-section .sponsors-container .sponsor.jcmf, .sponsors-section .sponsors-container .sponsor.jcmf .logo {
        height: 96px; }
      .sponsors-section .sponsors-container .sponsor.cfs, .sponsors-section .sponsors-container .sponsor.cfs .logo {
        height: 96px; }
      .sponsors-section .sponsors-container .sponsor.fzu, .sponsors-section .sponsors-container .sponsor.fzu .logo {
        height: 96px; }
      .sponsors-section .sponsors-container .sponsor.t-cz, .sponsors-section .sponsors-container .sponsor.t-cz .logo {
        height: 64px; }
      .sponsors-section .sponsors-container .sponsor.sab-aerospace, .sponsors-section .sponsors-container .sponsor.sab-aerospace .logo {
        height: 64px; }
  .sponsors-section .text {
    font-size: 28px; }
    .sponsors-section .text .link {
      color: black;
      white-space: nowrap; }

@media only screen and (min-width: 768px) {
  .sponsors-section {
    padding: 0 32px; }
    .sponsors-section .sponsors-container .sponsor.spse, .sponsors-section .sponsors-container .sponsor.spse .logo {
      height: 72px; }
    .sponsors-section .sponsors-container .sponsor.prusa-research, .sponsors-section .sponsors-container .sponsor.prusa-research .logo {
      height: 72px; }
    .sponsors-section .sponsors-container .sponsor.svitilny, .sponsors-section .sponsors-container .sponsor.svitilny .logo {
      height: 72px; }
    .sponsors-section .sponsors-container .sponsor.radio-shop, .sponsors-section .sponsors-container .sponsor.radio-shop .logo {
      height: 48px; } }

.socials-section .socials-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 32px; }
  .socials-section .socials-container .social {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px;
    cursor: pointer; }
    .socials-section .socials-container .social .logo {
      height: 48px; }

@media only screen and (min-width: 768px) {
  .socials-section .socials-container .social {
    height: 64px;
    margin: 0 16px; }
    .socials-section .socials-container .social .logo {
      height: 64px; } }

.progress-section .section-title {
  margin: 0;
  padding: 32px 0; }

.progress-section .progress-container {
  display: flex;
  flex-direction: row; }
  .progress-section .progress-container .odd-progress-container .progress-item, .progress-section .progress-container .even-progress-container .progress-item {
    width: calc(100% - 16px);
    padding: 8px;
    max-width: 400px; }
    .progress-section .progress-container .odd-progress-container .progress-item .dot, .progress-section .progress-container .even-progress-container .progress-item .dot {
      display: none; }
    .progress-section .progress-container .odd-progress-container .progress-item .item-title, .progress-section .progress-container .even-progress-container .progress-item .item-title {
      font-size: 24px;
      margin: 0; }
    .progress-section .progress-container .odd-progress-container .progress-item .item-date, .progress-section .progress-container .even-progress-container .progress-item .item-date {
      font-size: 18px;
      margin: 8px 0; }
    .progress-section .progress-container .odd-progress-container .progress-item .item-text, .progress-section .progress-container .even-progress-container .progress-item .item-text {
      margin: 0 0 6px 0; }
      .progress-section .progress-container .odd-progress-container .progress-item .item-text a, .progress-section .progress-container .even-progress-container .progress-item .item-text a {
        color: black; }
    .progress-section .progress-container .odd-progress-container .progress-item .item-link, .progress-section .progress-container .even-progress-container .progress-item .item-link {
      color: black; }
  .progress-section .progress-container .odd-progress-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center; }
  .progress-section .progress-container .even-progress-container {
    display: none; }

@media only screen and (min-width: 768px) {
  .progress-section .progress-container {
    margin: 0 32px; }
    .progress-section .progress-container .odd-progress-container, .progress-section .progress-container .even-progress-container {
      width: calc(50% - 18px); }
      .progress-section .progress-container .odd-progress-container .progress-item, .progress-section .progress-container .even-progress-container .progress-item {
        width: 318px;
        height: 150px;
        max-width: 318px;
        opacity: 0; }
        .progress-section .progress-container .odd-progress-container .progress-item .dot, .progress-section .progress-container .even-progress-container .progress-item .dot {
          display: block;
          width: 24px;
          height: 24px;
          min-width: 24px;
          background: black;
          border-radius: 50%;
          position: relative; }
        .progress-section .progress-container .odd-progress-container .progress-item.hide, .progress-section .progress-container .even-progress-container .progress-item.hide {
          visibility: hidden;
          height: 120px; }
        .progress-section .progress-container .odd-progress-container .progress-item.show, .progress-section .progress-container .even-progress-container .progress-item.show {
          transition: 1500ms opacity ease;
          opacity: 1; }
    .progress-section .progress-container .odd-progress-container {
      display: flex;
      border-right: solid 2px black;
      flex-direction: column;
      align-items: flex-end; }
      .progress-section .progress-container .odd-progress-container .progress-item {
        display: flex;
        flex-direction: row;
        align-items: center; }
        .progress-section .progress-container .odd-progress-container .progress-item .content {
          flex: 1; }
        .progress-section .progress-container .odd-progress-container .progress-item .dot {
          left: 22px; }
    .progress-section .progress-container .even-progress-container {
      display: flex;
      border-left: solid 2px black;
      align-self: flex-end;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 62px; }
      .progress-section .progress-container .even-progress-container .progress-item {
        display: flex;
        flex-direction: row;
        align-items: center; }
        .progress-section .progress-container .even-progress-container .progress-item .dot {
          left: -22px; }
        .progress-section .progress-container .even-progress-container .progress-item .content {
          padding-left: 64px; } }

@media only screen and (min-width: 1024px) {
  .progress-section .progress-container {
    margin: 0 48px; }
    .progress-section .progress-container .odd-progress-container .progress-item, .progress-section .progress-container .even-progress-container .progress-item {
      width: 432px;
      max-width: 432px; } }

@media only screen and (min-width: 1440px) {
  .progress-section .progress-container {
    margin: 0 72px; }
    .progress-section .progress-container .odd-progress-container .progress-item, .progress-section .progress-container .even-progress-container .progress-item {
      width: 520px;
      max-width: 520px; }
    .progress-section .progress-container .even-progress-container .progress-item {
      padding-right: 0; }
      .progress-section .progress-container .even-progress-container .progress-item .content {
        padding-left: 72px; } }

.articles-section {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .articles-section .articles-grid {
    display: grid;
    padding: 0 16px; }
    .articles-section .articles-grid .article {
      display: flex;
      flex-direction: column; }
      .articles-section .articles-grid .article-title {
        font-size: 32px;
        margin: 0 0 16px 0; }
      .articles-section .articles-grid .article-text {
        margin: 0 0 6px; }
      .articles-section .articles-grid .article-logo {
        margin-top: 16px;
        height: 32px;
        width: auto; }
      .articles-section .articles-grid .article-link {
        color: black;
        font-weight: 500; }
      .articles-section .articles-grid .article.hidden {
        display: none; }
  .articles-section .button-section {
    display: flex;
    justify-content: center;
    margin: 36px; }
    .articles-section .button-section .more-articles-button {
      background: transparent;
      border: 3px solid black;
      border-radius: 15px;
      font-size: 24px;
      cursor: pointer;
      width: 256px; }

@media only screen and (min-width: 768px) {
  .articles-section .articles-grid {
    margin: 0 32px;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
    max-width: 1100px; } }

.slideshow-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh; }
  .slideshow-section .title-container .section-title {
    margin: 0;
    padding: 32px 0;
    border-radius: 16px; }
  .slideshow-section .slideshow {
    position: relative;
    height: 35vh;
    width: 100%; }
    .slideshow-section .slideshow .image-container {
      transition-property: opacity;
      transition: ease-in-out;
      transition-duration: 500ms;
      opacity: 1;
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      width: 100%; }
      .slideshow-section .slideshow .image-container.hidden {
        opacity: 0; }
      .slideshow-section .slideshow .image-container .photo {
        width: 100%; }
  .slideshow-section .info-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .slideshow-section .info-container .text-background {
      border-radius: 16px;
      padding: 0 16px; }
      .slideshow-section .info-container .text-background .link {
        color: black; }
    .slideshow-section .info-container .arrow-down-button.white {
      display: none; }
    .slideshow-section .info-container .arrow-down-button {
      position: relative;
      margin-top: 48px;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer; }
      .slideshow-section .info-container .arrow-down-button .arrow-down {
        height: 32px; }
        .slideshow-section .info-container .arrow-down-button .arrow-down.white {
          display: none; }

@media only screen and (min-width: 768px) {
  .cansat-section.slideshow-section .title-container .section-title {
    padding-bottom: 12px;
    width: 250px; }
  .slideshow-section {
    display: block;
    flex-direction: unset;
    justify-content: unset;
    height: 100vh;
    position: relative; }
    .slideshow-section .title-container {
      display: flex;
      justify-content: center;
      position: relative;
      padding: 48px;
      z-index: 20; }
      .slideshow-section .title-container .section-title {
        background: white;
        margin-top: 0;
        width: 200px;
        font-size: 64px;
        padding: 8px 0; }
    .slideshow-section .slideshow {
      position: absolute;
      z-index: 10;
      height: 100vh;
      top: 0; }
    .slideshow-section .info-container {
      display: flex;
      align-items: center;
      position: absolute;
      flex-direction: column;
      bottom: 48px;
      z-index: 20;
      width: 100%; }
      .slideshow-section .info-container .text-background {
        background: white;
        width: calc(100% - 72px);
        max-width: 750px;
        margin: 0 36px;
        text-align: center;
        padding: 18px; }
        .slideshow-section .info-container .text-background .text {
          font-size: 24px;
          margin: 0; }
      .slideshow-section .info-container .arrow-down-button {
        position: relative;
        margin-top: 48px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer; }
        .slideshow-section .info-container .arrow-down-button .arrow-down {
          height: 32px; }
          .slideshow-section .info-container .arrow-down-button .arrow-down.white {
            display: block; }
          .slideshow-section .info-container .arrow-down-button .arrow-down.black {
            display: none; } }

body {
  margin: 0; }

* {
  font-family: 'Roboto', sans-serif; }

.section-title {
  text-align: center;
  font-size: 48px; }

@media only screen and (orientation: landscape) {
  .header-section {
    background-size: 100% auto; } }
